import React from 'react';

const WalletIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7976 12.5V17.5C21.7976 20.5 19.8178 22.5 16.8482 22.5H6.94939C3.97976 22.5 2 20.5 2 17.5V12.5C2 9.78 3.6234 7.88 6.14759 7.56C6.40496 7.52 6.67223 7.5 6.94939 7.5H16.8482C17.1056 7.5 17.353 7.50999 17.5906 7.54999C20.1445 7.84999 21.7976 9.76 21.7976 12.5Z"
        stroke="#012E46"
        strokeWidth="1.44362"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5919 7.55C17.3543 7.51 17.1069 7.50001 16.8495 7.50001H6.95073C6.67356 7.50001 6.40629 7.52001 6.14893 7.56001C6.28751 7.28001 6.48548 7.02001 6.72306 6.78001L9.94015 3.52C11.2963 2.16 13.4938 2.16 14.85 3.52L16.5822 5.29002C17.2158 5.92002 17.5523 6.72 17.5919 7.55Z"
        stroke="#012E46"
        strokeWidth="1.44362"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7975 13H18.8279C17.739 13 16.8481 13.9 16.8481 15C16.8481 16.1 17.739 17 18.8279 17H21.7975"
        stroke="#012E46"
        strokeWidth="1.44362"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WalletIcon;
