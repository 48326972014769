import React from 'react';
import closeIcon from './images/close.png';
import css from './Chip.module.css';

const Chip = props => {
  const { value, handleDelete } = props;
  return (
    <div className={css.wrapper}>
      <label className={css.label}>{value}</label>

      {handleDelete && <img className={css.icon} src={closeIcon} onClick={handleDelete} />}
    </div>
  );
};

export default Chip;
