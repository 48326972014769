import React from 'react';

const PaymentIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1002_80247)">
        <path d="M12 7V18" stroke="#013957" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12 7V18"
          stroke="black"
          strokeOpacity="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 9H10.75C10.2859 9 9.84075 9.18437 9.51256 9.51256C9.18437 9.84075 9 10.2859 9 10.75C9 11.2141 9.18437 11.6592 9.51256 11.9874C9.84075 12.3156 10.2859 12.5 10.75 12.5H13.25C13.7141 12.5 14.1592 12.6844 14.4874 13.0126C14.8156 13.3408 15 13.7859 15 14.25C15 14.7141 14.8156 15.1592 14.4874 15.4874C14.1592 15.8156 13.7141 16 13.25 16H9"
          stroke="#013957"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 9H10.75C10.2859 9 9.84075 9.18437 9.51256 9.51256C9.18437 9.84075 9 10.2859 9 10.75C9 11.2141 9.18437 11.6592 9.51256 11.9874C9.84075 12.3156 10.2859 12.5 10.75 12.5H13.25C13.7141 12.5 14.1592 12.6844 14.4874 13.0126C14.8156 13.3408 15 13.7859 15 14.25C15 14.7141 14.8156 15.1592 14.4874 15.4874C14.1592 15.8156 13.7141 16 13.25 16H9"
          stroke="black"
          strokeOpacity="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <circle cx="12" cy="12.5" r="9.35" stroke="#013957" strokeWidth="1.3" />
      <circle cx="12" cy="12.5" r="9.35" stroke="black" strokeOpacity="0.2" strokeWidth="1.3" />
      <defs>
        <clipPath id="clip0_1002_80247">
          <rect width="12" height="12" fill="white" transform="translate(6 6.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaymentIcon;
