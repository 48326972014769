import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const UserIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5898 12C14.3512 12 16.5898 9.76142 16.5898 7C16.5898 4.23858 14.3512 2 11.5898 2C8.82842 2 6.58984 4.23858 6.58984 7C6.58984 9.76142 8.82842 12 11.5898 12Z"
        stroke="#012E46"
        strokeWidth="1.39587"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.18 22C20.18 18.13 16.33 15 11.59 15C6.85 15 3 18.13 3 22"
        stroke="#012E46"
        strokeWidth="1.39587"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
