import React, { useState, useRef } from 'react';
import axios from 'axios';
import css from './FileUploadView.module.css';
import { Field } from 'react-final-form';
import { limitText, fileExtension } from './utils';
import downloadFileIcon from './images/downloadfile_icon.png'
import { FormattedMessage } from 'react-intl';
import cornerImage from './images/corner.png'
import { CssSharp } from '@mui/icons-material';
const fileDownload = require('js-file-download');

const FileUploadView = ({ value }) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';

  if (value) {
    const fileName = value.split('-')[1];

    const handleDownloadFile = () => {
      axios
        .get(`${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + value, {
          responseType: 'blob',
        })
        .then(resp => {
          fileDownload(resp.data, `${value}`);
        })
        .catch(error => {
          console.log(error);
        });
    };

    return (

      <div className={css.horizontalWrapper} title="download" onClick={handleDownloadFile}>

        <div className={css.fileContent}>
        </div>

        <div className={css.fileNameWrapper}>
          <div className={css.fileExtensionWrapper}>
            <label className={css.fileExtension}> {fileExtension(fileName)}</label>
          </div>
          <p className={css.fileName}>{limitText(fileName, 15)}</p>
          <img className={css.cornerImage} src={cornerImage} />
        </div>

      </div>
    );
  }

  return (<div />)
};



export default FileUploadView;
