import { accountTypes } from "./accountTypes";

export const getIsOwnerConsultant = (gig) => {
    return gig?.attributes?.publicData?.ownerType === accountTypes.consultant;
};

export const getIsOwnerClient = (gig) => {
    return gig?.attributes?.publicData?.ownerType === accountTypes.client;
};

export const getOwnerType = (gig) => {
    return gig?.attributes?.publicData?.ownerType
};