import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const StatisticIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 9L12.75 13.75L10.25 11.25L6.5 15"
        stroke="#013957"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 9L12.75 13.75L10.25 11.25L6.5 15"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.5 9H17.5V12" stroke="#013957" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.5 9H17.5V12"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="9.35" stroke="#013957" strokeWidth="1.3" />
      <circle cx="12" cy="12" r="9.35" stroke="black" strokeOpacity="0.2" strokeWidth="1.3" />
    </svg>
  );
};

export default StatisticIcon;
