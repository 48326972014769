import React, { useState, useRef } from 'react';
import axios from 'axios';
import css from './FileUploadDropField.module.css';
import { Field } from 'react-final-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { limitText, fileExtension } from './utils';
import FileDropZone from './FileDropZone/FileDropZone';
import downloadFileIcon from './images/downloadfile_icon.png'
import { FormattedMessage } from 'react-intl';
import cornerImage from './images/corner.png'
import { CssSharp } from '@mui/icons-material';
const fileDownload = require('js-file-download');

const FileUploadDropField = ({ name, id, submitFormOnChange, form, existingFile, handleDelete, onUpdateUploadFileStatus }) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
  const [uploadState, setUploadState] = useState({ isUploading: false, fileName: undefined });

  const uploadField = ({ input: { onChange, value }, label, ...rest }) => {
    const onFileChange = file => {
      const formData = new FormData();

      formData.append('image', file, file.name);

      setUploadState({ isUploading: true, fileName: file.name });

      if (onUpdateUploadFileStatus) {
        onUpdateUploadFileStatus(true);
      }

      return axios
        .post(`${isDev ? 'http://localhost:3500' : ''}/api/azure-upload`, formData)
        .then(resp => {
          const file = resp?.data?.file;
          onChange(file?.name);

          if (submitFormOnChange) {
            form.submit();
          }
          setUploadState({ isUploading: false, fileName: undefined });
        })
        .catch(error => {
          console.log(error);
        }).finally(() => {
          if (onUpdateUploadFileStatus) {
            onUpdateUploadFileStatus(false);
          }
        });
    };

    if (value === '') {
      value = existingFile;
    }

    if (value) {
      const fileName = value.split('-')[1];

      const handleDownloadFile = () => {
        axios
          .get(`${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + value, {
            responseType: 'blob',
          })
          .then(resp => {
            fileDownload(resp.data, `${value}`);
          })
          .catch(error => {
            console.log(error);
          });
      };

      const handleDeleteFile = e => {
        e.stopPropagation();
        e.preventDefault();

        return axios.get(
          `${isDev ? 'http://localhost:3500' : ''}/api/azure-delete?fileName=` + value,
          {
            responseType: 'blob',
          }
        ).then(resp => {
          onChange(null);

          if (submitFormOnChange) {
            form.submit();
          }

          if (handleDelete) {
            handleDelete(value)
          }
        });
      };

      return (

        <div className={css.horizontalWrapper} >

          <div className={css.fileContent}>
            {handleDelete &&
              <div className={css.fileOperations} >
                <img title="Download" className={css.downloadIcon} src={downloadFileIcon} onClick={handleDownloadFile} />
                <DeleteIcon title="Delete" className={css.deleteIcon} onClick={handleDeleteFile} />
              </div>
            }
          </div>

          <div className={css.fileNameWrapper}>
            <div className={css.fileExtensionWrapper}>
              <label className={css.fileExtension}> {fileExtension(fileName)}</label>
            </div>
            <p className={css.fileName}>{limitText(fileName, 15)}</p>
            <img className={css.cornerImage} src={cornerImage} />
          </div>


        </div>
      );
    }

    return (
      <div className={css.wrapper}>
        {uploadState.isUploading ?
          <div className={css.uploadInfo}>
            <FormattedMessage id="FileUploadDropField.uploadingFile" values={{ file: uploadState.fileName }} />
          </div> :
          <FileDropZone onImageUploadHandler={onFileChange} />
        }

      </div>
    );
  };

  return <Field id={id} name={name} component={uploadField} />;
};

export default FileUploadDropField;
