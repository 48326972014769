import React from 'react';

const CalendarIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7775 4.49976H5.09718C3.93894 4.49976 3 5.3952 3 6.49978V20.4999C3 21.6045 3.93894 22.4999 5.09718 22.4999H19.7775C20.9357 22.4999 21.8746 21.6045 21.8746 20.4999V6.49978C21.8746 5.3952 20.9357 4.49976 19.7775 4.49976Z"
        stroke="#013957"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7775 4.49976H5.09718C3.93894 4.49976 3 5.3952 3 6.49978V20.4999C3 21.6045 3.93894 22.4999 5.09718 22.4999H19.7775C20.9357 22.4999 21.8746 21.6045 21.8746 20.4999V6.49978C21.8746 5.3952 20.9357 4.49976 19.7775 4.49976Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6318 2.5V6.50004"
        stroke="#013957"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6318 2.5V6.50004"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24316 2.5V6.50004"
        stroke="#013957"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24316 2.5V6.50004"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10.5H21.8746"
        stroke="#013957"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10.5H21.8746"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.56002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
