import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import uploadFileIcon from './images/uploadfile_icon.png'
import css from './FileDropZone.module.css'
import { FormattedMessage } from "react-intl";

function FileDropZone(props) {
    const { form, onImageUploadHandler } = props;

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            onImageUploadHandler(file);
        })
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
            'application/pdf': []
        },
        onDrop,
    });

    return (
        <div className={css.dropzoneWrapper} {...getRootProps()}>
            <input {...getInputProps()} />
            <img className={css.uploadIcon} src={uploadFileIcon} />
            <p>
                <FormattedMessage id="FileDropZone.dragAndDropInfo" />
                <span className={css.infoHighlight}>
                    <FormattedMessage id="FileDropZone.chooseFile" />
                </span>
            </p>

        </div>
    )
}

export default FileDropZone;