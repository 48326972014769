import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const CardIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.70898H22"
        stroke="#013957"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.70898H22"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.2422H8"
        stroke="#013957"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.2422H8"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 16.2422H14.5"
        stroke="#013957"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 16.2422H14.5"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.44 4H17.55C21.11 4 22 4.82872 22 8.13417V15.8657C22 19.1712 21.11 19.9999 17.56 19.9999H6.44C2.89 20.0093 2 19.1806 2 15.8752V8.13417C2 4.82872 2.89 4 6.44 4Z"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.44 4H17.55C21.11 4 22 4.82872 22 8.13417V15.8657C22 19.1712 21.11 19.9999 17.56 19.9999H6.44C2.89 20.0093 2 19.1806 2 15.8752V8.13417C2 4.82872 2.89 4 6.44 4Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
